import React, { Component } from 'react';

import * as buttons from '../../constants/buttons.js';
import { groups } from '../../constants/groups.js';
import * as parts from '../../constants/parts.js';
import Context from '../../context/index.js';

export default class ApplyToAll extends Component {
  static contextType = Context;

  applyAllRight = () => {
    const { activeGroup, theme } = this.context;
    const background = theme[groups[activeGroup][0]];
    const letter = theme[groups[activeGroup][1]];

    this.context.setContext({
      theme: {
        ...this.context.theme,
        [parts.A_BUTTON_BACKGROUND]: background,
        [parts.B_BUTTON_BACKGROUND]: background,
        [parts.X_BUTTON_BACKGROUND]: background,
        [parts.Y_BUTTON_BACKGROUND]: background,
        [parts.A_BUTTON_TEXT]: letter,
        [parts.B_BUTTON_TEXT]: letter,
        [parts.X_BUTTON_TEXT]: letter,
        [parts.Y_BUTTON_TEXT]: letter
      }
    });
  };

  applyAllLeft = () => {
    const { activeGroup, theme } = this.context;
    const background = theme[groups[activeGroup][0]];
    const icon = theme[groups[activeGroup][1]];

    this.context.setContext({
      theme: {
        ...this.context.theme,
        [parts.UP_BUTTON_BACKGROUND]: background,
        [parts.RIGHT_BUTTON_BACKGROUND]: background,
        [parts.DOWN_BUTTON_BACKGROUND]: background,
        [parts.LEFT_BUTTON_BACKGROUND]: background,
        [parts.UP_BUTTON_ICON]: icon,
        [parts.RIGHT_BUTTON_ICON]: icon,
        [parts.DOWN_BUTTON_ICON]: icon,
        [parts.LEFT_BUTTON_ICON]: icon
      }
    });
  };

  render() {
    const { activeGroup } = this.context;

    const dpadButtons = [parts.UP_BUTTON, parts.RIGHT_BUTTON, parts.DOWN_BUTTON, parts.LEFT_BUTTON];
    const letterButtons = [parts.A_BUTTON, parts.B_BUTTON, parts.X_BUTTON, parts.Y_BUTTON];

    const dpadButtonsVisible = letterButtons.includes(activeGroup);
    const letterButtonsVisible = dpadButtons.includes(activeGroup);

    return (
      <>
        {dpadButtonsVisible && (
          <button className="mb-4" onClick={this.applyAllRight}>
            {buttons.APPLY_ALL_RIGHT}
          </button>
        )}
        {letterButtonsVisible && (
          <button className="mb-4" onClick={this.applyAllLeft}>
            {buttons.APPLY_ALL_LEFT}
          </button>
        )}
      </>
    );
  }
}
