import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Trigger extends Component {
  render() {
    return (
      <Part activePart={parts.RIGHT_TRIGGER} activeGroup={parts.RIGHT_TRIGGER} activeGroupParts={groups.RIGHT_TRIGGER}>
        {({ theme }) => (
          <path
            fill={theme[parts.RIGHT_TRIGGER]}
            d="M114.914,42.456c-9.991-20.628-29.824-39.835-64.08-42.253c0,0-14.583-0.224-20.592-0.201 c-0.55,0.002-0.991,0.45-0.991,1l0,43.785l79.25,1.625l2.042-0.75l3.897-1.866C114.939,43.556,115.155,42.955,114.914,42.456z"
          ></path>
        )}
      </Part>
    );
  }
}
