import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Right extends Component {
  render() {
    return (
      <Part
        activePart={parts.RIGHT_BUTTON_BACKGROUND}
        activeGroup={parts.RIGHT_BUTTON}
        activeGroupParts={groups.RIGHT_BUTTON}
      >
        {({ theme }) => (
          <g>
            <circle fill={theme[parts.RIGHT_BUTTON_BACKGROUND]} cx="84.433" cy="179.886" r="12.099"></circle>
            <polygon fill={theme[parts.RIGHT_BUTTON_ICON]} points="88.7,179.9 82.2,183.7 82.2,176.1"></polygon>
          </g>
        )}
      </Part>
    );
  }
}
