import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class B extends Component {
  render() {
    return (
      <Part activePart={parts.B_BUTTON_BACKGROUND} activeGroup={parts.B_BUTTON} activeGroupParts={groups.B_BUTTON}>
        {({ theme }) => (
          <g>
            <circle fill={theme[parts.B_BUTTON_BACKGROUND]} cx="61.42" cy="111.89" r="12.099"></circle>
            <path
              fill={theme[parts.B_BUTTON_TEXT]}
              d="M61.6,106.8c0.6,0,1.2,0.1,1.6,0.2c0.5,0.1,0.8,0.3,1.1,0.5c0.3,0.2,0.5,0.5,0.7,0.8c0.1,0.3,0.2,0.7,0.2,1.1
                c0,0.3,0,0.5-0.1,0.7s-0.2,0.4-0.4,0.7s-0.4,0.4-0.6,0.5s-0.5,0.3-0.9,0.4c0.8,0.1,1.3,0.4,1.7,0.8c0.4,0.4,0.6,0.9,0.6,1.6
                c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.4-0.4,0.7-0.7,0.9c-0.3,0.3-0.7,0.5-1.1,0.6c-0.5,0.1-1,0.2-1.5,0.2h-3.6v-10.3H61.6z M59.7,107.9
                v3.5h1.8c0.4,0,0.7,0,1-0.1s0.5-0.2,0.7-0.4c0.2-0.2,0.3-0.3,0.4-0.6c0.1-0.2,0.1-0.5,0.1-0.7c0-0.6-0.2-1.1-0.5-1.3
                c-0.4-0.3-0.9-0.4-1.7-0.4H59.7z M62,116c0.4,0,0.7,0,1-0.1c0.3-0.1,0.5-0.2,0.7-0.4s0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.5,0.1-0.8
                c0-0.5-0.2-1-0.6-1.3c-0.4-0.3-0.9-0.5-1.7-0.5h-2.2v3.6H62z"
            ></path>
          </g>
        )}
      </Part>
    );
  }
}
