import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Minus extends Component {
  render() {
    return (
      <Part activePart={parts.MINUS_BUTTON} activeGroup={parts.MINUS_BUTTON} activeGroupParts={groups.MINUS_BUTTON}>
        {({ theme }) => <rect fill={theme[parts.MINUS_BUTTON]} height="5.667" width="18" x="82" y="33.12"></rect>}
      </Part>
    );
  }
}
