import React, { Component } from 'react';
import classNames from 'classnames';

import { labels } from '../../constants/labels.js';
import { labelsDetails } from '../../constants/label-details.js';
import Context, { initialContext } from '../../context/index.js';
import * as parts from '../../constants/parts.js';

import { FaTrash } from '../Icons/Icons.js';

export default class Parts extends Component {
  static contextType = Context;

  selectPart(part) {
    this.context.setContext({ activePart: part });
  }

  resetActivePart = part => {
    this.context.setContext({
      activeColor: initialContext.theme[[part]],
      theme: { ...this.context.theme, [part]: initialContext.theme[[part]] }
    });
  };

  render() {
    const { activePart, activeGroup, activeGroupParts, theme } = this.context;

    return (
      <div id="color-picker-buttons">
        <div className="parts">
          <div className="part-group">
            <span>{labelsDetails[activeGroup].type}</span>
            <span>{labelsDetails[activeGroup].value}</span>
          </div>
          {activeGroupParts.map(part => (
            <div className="flex" key={labels[part]}>
              <button className={classNames({ active: part === activePart })} onClick={() => this.selectPart(part)}>
                {labels[part]}
              </button>

              <button
                className="button-small ml-4"
                disabled={theme[part] === initialContext.theme[part]}
                onClick={() => this.resetActivePart(part)}
                title={`Reset ${labels[part]}`}
              >
                {part === parts.LEFT_JOYCON_PATTERN || part === parts.RIGHT_JOYCON_PATTERN ? (
                  <span
                    className="trash-part-swatch"
                    style={{
                      backgroundColor:
                        part === parts.LEFT_JOYCON_PATTERN ? theme[parts.LEFT_JOYCON] : theme[parts.RIGHT_JOYCON],
                      backgroundImage: `url(${theme[part]})`
                    }}
                  />
                ) : (
                  <span className="trash-part-swatch" style={{ backgroundColor: theme[part] }} />
                )}

                <span style={{ color: '#777' }}>
                  <FaTrash />
                </span>
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
