import React, { Component } from 'react';
import classNames from 'classnames';

import * as parts from '../../constants/parts.js';
import * as buttons from '../../constants/buttons.js';
import { labels } from '../../constants/labels.js';
import Context, { initialContext } from '../../context/index.js';
import { presetColors } from '../../constants/preset-colors.js';

export default class Tutorial extends Component {
  static contextType = Context;

  componentDidUpdate() {
    const { activeGroup, darkBackgroundEnabled, setContext, theme, tutorialIndex } = this.context;

    if (tutorialIndex === 0 && activeGroup === parts.LEFT_JOYCON) {
      setContext({ tutorialIndex: 1 });
    }

    if (
      tutorialIndex === 1 &&
      theme[parts.LEFT_JOYCON] !== presetColors.find(color => color.name === 'Neon Blue').hex
    ) {
      setContext({ outlineGroup: parts.PLUS_BUTTON, tutorialIndex: 2 });
    }

    if (tutorialIndex === 2 && activeGroup === parts.PLUS_BUTTON) {
      setContext({ tutorialIndex: 3 });
    }

    if (tutorialIndex === 3 && theme[parts.PLUS_BUTTON] !== '#333') {
      setContext({ outlineGroup: parts.HOME_BUTTON, tutorialIndex: 4 });
    }

    if (tutorialIndex === 4 && activeGroup === parts.HOME_BUTTON) {
      setContext({ outlineGroup: parts.HOME_BUTTON, tutorialIndex: 5 });
    }

    if (
      tutorialIndex === 5 &&
      activeGroup === parts.HOME_BUTTON &&
      theme[parts.HOME_BUTTON_BACKGROUND] !== '#333' &&
      theme[parts.HOME_BUTTON_ICON] !== '#222' &&
      theme[parts.HOME_BUTTON_LIGHT] !== '#787878'
    ) {
      setContext({ outlineGroup: buttons.CLOSE, tutorialIndex: 7 });
    }

    if (tutorialIndex === 6 && activeGroup === parts.HOME_BUTTON && darkBackgroundEnabled === false) {
      setContext({ outlineGroup: buttons.TUTORIAL, tutorialIndex: 7 });
    }
  }

  defaultColor = initialContext.theme[parts.RIGHT_JOYCON];

  isUpdated(part) {
    const color = this.context.theme[part];
    const initialColor = initialContext.theme[part];

    return initialColor !== color ? '#1EDC00' : this.defaultColor;
  }

  render() {
    const { tutorialIndex } = this.context;
    const Strong = props => <strong style={{ color: props.color || this.defaultColor }}>{props.text}</strong>;

    return (
      <div id="tutorial">
        {tutorialIndex === 0 && (
          <div className={classNames(['animated', 'fadeIn', 'faster'])}>
            <p>
              Click on the <Strong text="Part" /> you wish to edit. You can customize the <Strong text="Joy-Cons" />,{' '}
              <Strong text="Buttons" />, <Strong text="Triggers" />, <Strong text="Sticks" />, and the{' '}
              <Strong text="Rails" />.
            </p>
            <p>
              Click on the <Strong text="Left Joy-Con" /> to get started.
            </p>
          </div>
        )}

        {tutorialIndex === 1 && (
          <div className={classNames(['animated', 'fadeInUp', 'faster'])}>
            <p>
              Change the color of the <Strong text="Left Joy-Con" /> using the color picker below.
            </p>
            <p>
              <Strong text="Tip:" /> The colors under the color picker are official <Strong text="Joy-Con" /> colors, be
              sure to try them out.
            </p>
          </div>
        )}

        {tutorialIndex === 2 && (
          <div className={classNames(['animated', 'fadeIn', 'faster'])}>
            <p>
              Let's customize a <Strong text="Button" /> now. Click on the <Strong text="+ Button" /> to continue.
            </p>
          </div>
        )}

        {tutorialIndex === 3 && (
          <div className={classNames(['animated', 'fadeInUp', 'faster'])}>
            <p>
              Change the color of the <Strong text="+ Button" />.
            </p>
            <p>
              <Strong text="Tip:" /> As you make changes to your theme, the <Strong text="URL" /> is automatically
              updated allowing you to share your creations.
            </p>
          </div>
        )}

        {tutorialIndex === 4 && (
          <div className="tutorial animated fadeInUp faster">
            <p>
              Now let's edit a more customizable <Strong text="Button" />. Click the{' '}
              <Strong text={labels[parts.HOME_BUTTON]} />.
            </p>

            <p>
              <Strong text="Tip:" /> You can reset each <Strong text="Part" /> to default using the button to the right
              of the corresponding <Strong text="Part" /> below.
            </p>
          </div>
        )}

        {tutorialIndex === 5 && (
          <div className="tutorial animated fadeInUp faster">
            <p>
              Notice the three buttons above the color picker? The <Strong text={labels[parts.HOME_BUTTON]} /> has
              additional parts to customize.
            </p>
            <p>
              Go ahead and change the{' '}
              <Strong
                color={this.isUpdated(parts.HOME_BUTTON_BACKGROUND)}
                text={labels[parts.HOME_BUTTON_BACKGROUND]}
              />
              , <Strong color={this.isUpdated(parts.HOME_BUTTON_ICON)} text={labels[parts.HOME_BUTTON_ICON]} />, and{' '}
              <Strong color={this.isUpdated(parts.HOME_BUTTON_LIGHT)} text={labels[parts.HOME_BUTTON_LIGHT]} />.
            </p>
          </div>
        )}
        {tutorialIndex === 6 && (
          <div className="tutorial animated fadeInUp faster">
            <p>
              You can toggle between between <Strong text="Light" /> and <Strong text="Dark" /> backgrounds by selecting{' '}
              <Strong text={buttons.TOGGLE_BACKGROUND} /> below.
            </p>
            <p>Give it a try to continue.</p>
          </div>
        )}

        {tutorialIndex === 7 && (
          <div className="tutorial animated fadeInUp faster">
            <p>
              That's it, time to craft your very own <Strong text="Joy-Con" />. Share your creation online using the
              current <Strong text="URL" />.
            </p>
            <p>
              Exit the <Strong text="Help Guide" /> using the button below.
            </p>
          </div>
        )}
      </div>
    );
  }
}
