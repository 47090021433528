import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class JoyCon extends Component {
  render() {
    return (
      <Part
        activePart={parts.LEFT_JOYCON}
        activeGroup={parts.LEFT_JOYCON}
        activeGroupParts={groups.LEFT_JOYCON}
        smallHover={true}
      >
        {({ theme }) => (
          <>
            <defs>
              <pattern
                id="left-pattern"
                patternUnits="userSpaceOnUse"
                width={theme[parts.LEFT_JOYCON_PATTERN_WIDTH] / 2}
                height={theme[parts.LEFT_JOYCON_PATTERN_HEIGHT] / 2}
              >
                <image
                  href={theme[parts.LEFT_JOYCON_PATTERN]}
                  x="0"
                  y="0"
                  width={theme[parts.LEFT_JOYCON_PATTERN_WIDTH] / 2}
                  height={theme[parts.LEFT_JOYCON_PATTERN_HEIGHT] / 2}
                />
              </pattern>
            </defs>
            <path
              fill={theme[parts.LEFT_JOYCON]}
              d="M65,335.786c-35.928,0-65-33.029-65-67.667l1-198C1,34.192,34.404,3.953,69.042,3.953h37.282 c1.11,0,2.01,0.9,2.01,2.01v327.813c0,1.11-0.9,2.01-2.01,2.01H65"
            ></path>
            <path
              fill="url(#left-pattern)"
              d="M65,335.786c-35.928,0-65-33.029-65-67.667l1-198C1,34.192,34.404,3.953,69.042,3.953h37.282 c1.11,0,2.01,0.9,2.01,2.01v327.813c0,1.11-0.9,2.01-2.01,2.01H65"
            ></path>
          </>
        )}
      </Part>
    );
  }
}
