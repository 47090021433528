import React from 'react';

import { theme } from './theme.js';

const Context = React.createContext();

export const initialContext = {
  activePart: null,
  activeGroup: null,
  activeGroupParts: [],
  activeColor: '#000',
  darkBackgroundEnabled: true,
  enlargeSettingEnabled: true,
  outlineSettingEnabled: false,
  outlineGroup: '',
  theme,
  tutorialIndex: -1
};

export default Context;
