import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Up extends Component {
  render() {
    return (
      <Part activePart={parts.UP_BUTTON_BACKGROUND} activeGroup={parts.UP_BUTTON} activeGroupParts={groups.UP_BUTTON}>
        {({ theme }) => (
          <g>
            <circle fill={theme[parts.UP_BUTTON_BACKGROUND]} cx="58.099" cy="154.687" r="12.099"></circle>
            <polygon fill={theme[parts.UP_BUTTON_ICON]} points="58.1,150.4 61.9,157 54.3,157"></polygon>
          </g>
        )}
      </Part>
    );
  }
}
