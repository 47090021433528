import React, { Component } from 'react';

import Rail from './Parts/Left/Rail.js';
import Trigger from './Parts/Left/Trigger.js';
import JoyCon from './Parts/Left/JoyCon.js';
import Minus from './Parts/Left/Minus.js';
import Thumbstick from './Parts/Left/Thumbstick.js';
import Up from './Parts/Left/Up.js';
import Right from './Parts/Left/Right.js';
import Down from './Parts/Left/Down.js';
import Left from './Parts/Left/Left.js';
import Screenshot from './Parts/Left/Screenshot.js';

export default class LeftJoyCon extends Component {
  render() {
    return (
      <div className="joy-cons" id="left-joy-con">
        <svg
          className="animated bounceInUp fast"
          version="1.1"
          viewBox="0 0 118 335"
          x="0px"
          y="0px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Rail />
          <Trigger />
          <JoyCon />
          <Minus />
          <Thumbstick />
          <Up />
          <Right />
          <Down />
          <Left />
          <Screenshot />
        </svg>
      </div>
    );
  }
}
