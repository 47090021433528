export const TUTORIAL = 'Help Guide';
export const TOGGLE_BACKGROUND = 'Toggle Background';
export const RESET_THEME = 'Reset';
export const RESET = 'Reset';
export const CLOSE = 'Close';
export const END_TUTORIAL = 'Exit Help Guide';
export const OUTLINE = 'Outline';
export const ENLARGE = 'Enlarge';
export const ON = 'On';
export const OFF = 'Off';
export const APPLY_ALL_RIGHT = 'Sync Ⓐ Ⓑ Ⓧ Ⓨ Buttons';
export const APPLY_ALL_LEFT = 'Sync Directional Buttons';
