import * as parts from './parts.js';

export const labelsDetails = {
  [parts.HOME_BUTTON]: { type: 'Button', value: 'HOME' },
  [parts.SCREENSHOT_BUTTON]: { type: 'Button', value: 'Capture' },

  [parts.PLUS_BUTTON]: { type: 'Button', value: '+' },
  [parts.MINUS_BUTTON]: { type: 'Button', value: '–' },

  [parts.LEFT_THUMBSTICK]: { type: 'Stick', value: 'Left' },
  [parts.RIGHT_THUMBSTICK]: { type: 'Stick', value: 'Right' },

  [parts.UP_BUTTON]: { type: 'Directional Button', value: 'Up' },
  [parts.RIGHT_BUTTON]: { type: 'Directional Button', value: 'Right' },
  [parts.DOWN_BUTTON]: { type: 'Directional Button', value: 'Down' },
  [parts.LEFT_BUTTON]: { type: 'Directional Button', value: 'Left' },

  [parts.A_BUTTON]: { type: 'Button', value: 'A' },
  [parts.B_BUTTON]: { type: 'Button', value: 'B' },
  [parts.X_BUTTON]: { type: 'Button', value: 'X' },
  [parts.Y_BUTTON]: { type: 'Button', value: 'Y' },

  [parts.LEFT_RAIL]: { type: 'Rail', value: 'Left' },
  [parts.LEFT_TRIGGER]: { type: 'Button', value: 'L' },
  [parts.LEFT_JOYCON]: { type: 'Joy-Con', value: 'Left' },

  [parts.RIGHT_RAIL]: { type: 'Rail', value: 'Right' },
  [parts.RIGHT_TRIGGER]: { type: 'Button', value: 'R' },
  [parts.RIGHT_JOYCON]: { type: 'Joy-Con', value: 'Right' }
};
