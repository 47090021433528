import * as parts from '../constants/parts.js';
import { presetColors } from '../constants/preset-colors.js';

export const theme = {
  [parts.LEFT_RAIL]: '#333',
  [parts.LEFT_TRIGGER]: '#333',
  [parts.LEFT_JOYCON]: presetColors.find(color => color.name === 'Neon Blue').hex,
  [parts.LEFT_JOYCON_PATTERN]: '',
  [parts.LEFT_JOYCON_PATTERN_WIDTH]: 0,
  [parts.LEFT_JOYCON_PATTERN_HEIGHT]: 0,
  [parts.MINUS_BUTTON]: '#333',
  [parts.LEFT_THUMBSTICK_TOP]: '#333',
  [parts.LEFT_THUMBSTICK_BOTTOM]: '#222',
  [parts.UP_BUTTON_BACKGROUND]: '#333',
  [parts.UP_BUTTON_ICON]: '#111',
  [parts.RIGHT_BUTTON_BACKGROUND]: '#333',
  [parts.RIGHT_BUTTON_ICON]: '#111',
  [parts.DOWN_BUTTON_BACKGROUND]: '#333',
  [parts.DOWN_BUTTON_ICON]: '#111',
  [parts.LEFT_BUTTON_BACKGROUND]: '#333',
  [parts.LEFT_BUTTON_ICON]: '#111',
  [parts.SCREENSHOT_BUTTON_BACKGROUND]: '#333',
  [parts.SCREENSHOT_BUTTON_ICON]: '#222',
  [parts.RIGHT_RAIL]: '#333',
  [parts.RIGHT_TRIGGER]: '#333',
  [parts.RIGHT_JOYCON]: presetColors.find(color => color.name === 'Neon Red').hex,
  [parts.RIGHT_JOYCON_PATTERN]: '',
  [parts.RIGHT_JOYCON_PATTERN_WIDTH]: 0,
  [parts.RIGHT_JOYCON_PATTERN_HEIGHT]: 0,
  [parts.PLUS_BUTTON]: '#333',
  [parts.A_BUTTON_TEXT]: '#fff',
  [parts.A_BUTTON_BACKGROUND]: '#333',
  [parts.B_BUTTON_TEXT]: '#fff',
  [parts.B_BUTTON_BACKGROUND]: '#333',
  [parts.X_BUTTON_TEXT]: '#fff',
  [parts.X_BUTTON_BACKGROUND]: '#333',
  [parts.Y_BUTTON_TEXT]: '#fff',
  [parts.Y_BUTTON_BACKGROUND]: '#333',
  [parts.RIGHT_THUMBSTICK_TOP]: '#333',
  [parts.RIGHT_THUMBSTICK_BOTTOM]: '#222',
  [parts.HOME_BUTTON_BACKGROUND]: '#333',
  [parts.HOME_BUTTON_LIGHT]: '#787878',
  [parts.HOME_BUTTON_ICON]: '#222'
};
