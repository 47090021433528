import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Trigger extends Component {
  render() {
    return (
      <Part activePart={parts.LEFT_TRIGGER} activeGroup={parts.LEFT_TRIGGER} activeGroupParts={groups.LEFT_TRIGGER}>
        {({ theme }) => (
          <path
            fill={theme[parts.LEFT_TRIGGER]}
            d="M3.253,42.456c9.991-20.628,29.824-39.835,64.08-42.253c0,0,14.583-0.224,20.592-0.201 c0.55,0.002,0.991,0.45,0.991,1l0,43.785l-79.25,1.625l-2.042-0.75l-3.897-1.866C3.228,43.556,3.011,42.955,3.253,42.456z"
          ></path>
        )}
      </Part>
    );
  }
}
