import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Thumbstick extends Component {
  render() {
    return (
      <Part
        activePart={parts.LEFT_THUMBSTICK_TOP}
        activeGroup={parts.LEFT_THUMBSTICK}
        activeGroupParts={groups.LEFT_THUMBSTICK}
      >
        {({ theme }) => (
          <g>
            <circle fill={theme[parts.LEFT_THUMBSTICK_BOTTOM]} cx="57.938" cy="86.89" r="25.191"></circle>
            <circle fill={theme[parts.LEFT_THUMBSTICK_TOP]} cx="57.938" cy="86.89" r="18.637"></circle>
          </g>
        )}
      </Part>
    );
  }
}
