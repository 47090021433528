import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class JoyCon extends Component {
  render() {
    return (
      <Part
        activePart={parts.RIGHT_JOYCON}
        activeGroup={parts.RIGHT_JOYCON}
        activeGroupParts={groups.RIGHT_JOYCON}
        smallHover={true}
      >
        {({ theme }) => (
          <>
            <defs>
              <pattern
                id="right-pattern"
                patternUnits="userSpaceOnUse"
                width={theme[parts.RIGHT_JOYCON_PATTERN_WIDTH] / 2}
                height={theme[parts.RIGHT_JOYCON_PATTERN_HEIGHT] / 2}
              >
                <image
                  href={theme[parts.RIGHT_JOYCON_PATTERN]}
                  x="0"
                  y="0"
                  width={theme[parts.RIGHT_JOYCON_PATTERN_WIDTH] / 2}
                  height={theme[parts.RIGHT_JOYCON_PATTERN_HEIGHT] / 2}
                />
              </pattern>
            </defs>

            <path
              fill={theme[parts.RIGHT_JOYCON]}
              d="M53.167,335.786c35.928,0,65-33.029,65-67.667l-1-198c0-35.928-33.404-66.167-68.042-66.167H11.843 c-1.11,0-2.01,0.9-2.01,2.01v327.813c0,1.11,0.9,2.01,2.01,2.01H53.167"
            ></path>
            <path
              fill="url(#right-pattern)"
              d="M53.167,335.786c35.928,0,65-33.029,65-67.667l-1-198c0-35.928-33.404-66.167-68.042-66.167H11.843 c-1.11,0-2.01,0.9-2.01,2.01v327.813c0,1.11,0.9,2.01,2.01,2.01H53.167"
            ></path>
          </>
        )}
      </Part>
    );
  }
}
