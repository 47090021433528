import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Rail extends Component {
  render() {
    return (
      <Part
        activePart={parts.LEFT_RAIL}
        activeGroup={parts.LEFT_RAIL}
        activeGroupParts={groups.LEFT_RAIL}
        smallHover={true}
      >
        {({ theme }) => (
          <path
            fill={theme[parts.LEFT_RAIL]}
            d="M104.667,288.453h6.25c0.552,0,1,0.448,1,1v9.73c0,0.177,0.047,0.351,0.136,0.504l1.324,2.27 c0.179,0.307,0.508,0.496,0.864,0.496h2.926c0.552,0,1-0.448,1-1v-53.797c0-0.134-0.027-0.267-0.079-0.391l-1.991-4.692 c-0.159-0.375-0.075-0.809,0.213-1.098l1.564-1.564c0.188-0.188,0.293-0.442,0.293-0.707v-24.56c0-0.329-0.162-0.637-0.433-0.824 l-1.568-1.078c-0.388-0.267-0.537-0.768-0.359-1.204l2.286-5.571c0.049-0.12,0.075-0.249,0.075-0.38v-93.303 c0-0.135-0.027-0.269-0.08-0.393l-1.873-4.385c-0.212-0.496,0.007-1.07,0.496-1.299l0.882-0.413 c0.352-0.165,0.576-0.518,0.576-0.906V80.367c0-0.265-0.105-0.52-0.293-0.707l-1.957-1.957c-0.3-0.3-0.378-0.756-0.195-1.139 l2.347-4.907c0.064-0.135,0.098-0.282,0.098-0.431V22.703c0-0.552-0.448-1-1-1h-12.25"
          ></path>
        )}
      </Part>
    );
  }
}
