export const SCREENSHOT_BUTTON = 'SCREENSHOT_BUTTON';
export const SCREENSHOT_BUTTON_BACKGROUND = 'SCREENSHOT_BUTTON_BACKGROUND';
export const SCREENSHOT_BUTTON_ICON = 'SCREENSHOT_BUTTON_ICON';

export const HOME_BUTTON = 'HOME_BUTTON';
export const HOME_BUTTON_BACKGROUND = 'HOME_BUTTON_BACKGROUND';
export const HOME_BUTTON_ICON = 'HOME_BUTTON_ICON';
export const HOME_BUTTON_LIGHT = 'HOME_BUTTON_LIGHT';

export const LEFT_THUMBSTICK = 'LEFT_THUMBSTICK';
export const LEFT_THUMBSTICK_TOP = 'LEFT_THUMBSTICK_TOP';
export const LEFT_THUMBSTICK_BOTTOM = 'LEFT_THUMBSTICK_BOTTOM';

export const RIGHT_THUMBSTICK = 'RIGHT_THUMBSTICK';
export const RIGHT_THUMBSTICK_TOP = 'RIGHT_THUMBSTICK_TOP';
export const RIGHT_THUMBSTICK_BOTTOM = 'RIGHT_THUMBSTICK_BOTTOM';

export const UP_BUTTON = 'UP_BUTTON';
export const UP_BUTTON_BACKGROUND = 'UP_BUTTON_BACKGROUND';
export const UP_BUTTON_ICON = 'UP_BUTTON_ICON';

export const RIGHT_BUTTON = 'RIGHT_BUTTON';
export const RIGHT_BUTTON_BACKGROUND = 'RIGHT_BUTTON_BACKGROUND';
export const RIGHT_BUTTON_ICON = 'RIGHT_BUTTON_ICON';

export const DOWN_BUTTON = 'DOWN_BUTTON';
export const DOWN_BUTTON_BACKGROUND = 'DOWN_BUTTON_BACKGROUND';
export const DOWN_BUTTON_ICON = 'DOWN_BUTTON_ICON';

export const LEFT_BUTTON = 'LEFT_BUTTON';
export const LEFT_BUTTON_BACKGROUND = 'LEFT_BUTTON_BACKGROUND';
export const LEFT_BUTTON_ICON = 'LEFT_BUTTON_ICON';

export const A_BUTTON = 'A_BUTTON';
export const A_BUTTON_BACKGROUND = 'A_BUTTON_BACKGROUND';
export const A_BUTTON_TEXT = 'A_BUTTON_TEXT';

export const B_BUTTON = 'B_BUTTON';
export const B_BUTTON_BACKGROUND = 'B_BUTTON_BACKGROUND';
export const B_BUTTON_TEXT = 'B_BUTTON_TEXT';

export const X_BUTTON = 'X_BUTTON';
export const X_BUTTON_BACKGROUND = 'X_BUTTON_BACKGROUND';
export const X_BUTTON_TEXT = 'X_BUTTON_TEXT';

export const Y_BUTTON = 'Y_BUTTON';
export const Y_BUTTON_BACKGROUND = 'Y_BUTTON_BACKGROUND';
export const Y_BUTTON_TEXT = 'Y_BUTTON_TEXT';

export const MINUS_BUTTON = 'MINUS_BUTTON';
export const PLUS_BUTTON = 'PLUS_BUTTON';

export const LEFT_JOYCON = 'LEFT_JOYCON';
export const LEFT_JOYCON_PATTERN = 'LEFT_JOYCON_PATTERN';
export const LEFT_JOYCON_PATTERN_WIDTH = 'LEFT_JOYCON_PATTERN_WIDTH';
export const LEFT_JOYCON_PATTERN_HEIGHT = 'LEFT_JOYCON_PATTERN_HEIGHT';
export const LEFT_RAIL = 'LEFT_RAIL';
export const LEFT_TRIGGER = 'LEFT_TRIGGER';

export const RIGHT_JOYCON = 'RIGHT_JOYCON';
export const RIGHT_JOYCON_PATTERN = 'RIGHT_JOYCON_PATTERN';
export const RIGHT_JOYCON_PATTERN_WIDTH = 'RIGHT_JOYCON_PATTERN_WIDTH';
export const RIGHT_JOYCON_PATTERN_HEIGHT = 'RIGHT_JOYCON_PATTERN_HEIGHT';
export const RIGHT_RAIL = 'RIGHT_RAIL';
export const RIGHT_TRIGGER = 'RIGHT_TRIGGER';
