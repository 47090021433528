import * as parts from './parts.js';

export const groups = {
  [parts.SCREENSHOT_BUTTON]: [parts.SCREENSHOT_BUTTON_BACKGROUND, parts.SCREENSHOT_BUTTON_ICON],
  [parts.HOME_BUTTON]: [parts.HOME_BUTTON_BACKGROUND, parts.HOME_BUTTON_ICON, parts.HOME_BUTTON_LIGHT],
  [parts.LEFT_THUMBSTICK]: [parts.LEFT_THUMBSTICK_TOP, parts.LEFT_THUMBSTICK_BOTTOM],
  [parts.RIGHT_THUMBSTICK]: [parts.RIGHT_THUMBSTICK_TOP, parts.RIGHT_THUMBSTICK_BOTTOM],
  [parts.UP_BUTTON]: [parts.UP_BUTTON_BACKGROUND, parts.UP_BUTTON_ICON],
  [parts.RIGHT_BUTTON]: [parts.RIGHT_BUTTON_BACKGROUND, parts.RIGHT_BUTTON_ICON],
  [parts.DOWN_BUTTON]: [parts.DOWN_BUTTON_BACKGROUND, parts.DOWN_BUTTON_ICON],
  [parts.LEFT_BUTTON]: [parts.LEFT_BUTTON_BACKGROUND, parts.LEFT_BUTTON_ICON],
  [parts.A_BUTTON]: [parts.A_BUTTON_BACKGROUND, parts.A_BUTTON_TEXT],
  [parts.B_BUTTON]: [parts.B_BUTTON_BACKGROUND, parts.B_BUTTON_TEXT],
  [parts.X_BUTTON]: [parts.X_BUTTON_BACKGROUND, parts.X_BUTTON_TEXT],
  [parts.Y_BUTTON]: [parts.Y_BUTTON_BACKGROUND, parts.Y_BUTTON_TEXT],
  [parts.MINUS_BUTTON]: [parts.MINUS_BUTTON],
  [parts.PLUS_BUTTON]: [parts.PLUS_BUTTON],
  [parts.LEFT_JOYCON]: [parts.LEFT_JOYCON, parts.LEFT_JOYCON_PATTERN],
  [parts.LEFT_TRIGGER]: [parts.LEFT_TRIGGER],
  [parts.LEFT_RAIL]: [parts.LEFT_RAIL],
  [parts.RIGHT_JOYCON]: [parts.RIGHT_JOYCON, parts.RIGHT_JOYCON_PATTERN],
  [parts.RIGHT_TRIGGER]: [parts.RIGHT_TRIGGER],
  [parts.RIGHT_RAIL]: [parts.RIGHT_RAIL]
};
