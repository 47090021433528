import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

import Context from '../../context/index.js';
import { presetColors } from '../../constants/preset-colors.js';

export default class ColorPicker extends Component {
  static contextType = Context;

  state = {
    presetColors: presetColors.map(color => ({ color: color.hex, title: color.name }))
  };

  setActiveColor = () => {
    this.context.setContext({
      activeColor: this.context.theme[this.context.activePart]
    });
  };

  componentDidMount() {
    this.setActiveColor();
  }

  componentDidUpdate() {
    if (this.context.activeColor !== this.context.theme[this.context.activePart]) {
      this.setActiveColor();
    }
  }

  render() {
    return (
      <div className="custom-sketch-picker-container">
        <div className="custom-sketch-picker">
          <SketchPicker
            color={this.context.activeColor}
            disableAlpha={true}
            onChange={color => this.props.setColor({ color })}
            presetColors={this.state.presetColors}
            width="260px"
          />
        </div>
      </div>
    );
  }
}
