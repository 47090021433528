export const presetColors = [
  { name: 'Neon Blue', hex: '#0ab9e6' },
  { name: 'Neon Red', hex: '#ff3c28' },
  { name: 'Blue', hex: '#4655f5' },
  { name: 'Neon Yellow', hex: '#e6ff00' },
  { name: 'Neon Pink', hex: '#ff3278' },
  { name: 'Neon Green', hex: '#1edc00' },
  { name: 'Neon Purple', hex: '#b400e6' },
  { name: 'Neon Orange', hex: '#faa005' },
  { name: 'Gray', hex: '#828282' },
  { name: 'White', hex: '#e6e6e6' },
  { name: 'Blue', hex: '#455aa5' },
  { name: 'Coral', hex: '#ff7d81' },
  { name: 'Turquoise', hex: '#01b2b6' },
  { name: 'Yellow', hex: '#f6cd54' },
  { name: 'Animal Crossing Pastel-Green', hex: '#82ff96' },
  { name: 'Animal Crossing Pastel-Blue', hex: '#96f5f5' },
  { name: 'Fortnite Dark-Blue', hex: '#0084ff' },
  { name: 'Fortnite Yellow', hex: '#ffcc00' },
  { name: 'Legend of Zelda Dark-Blue', hex: '#2d50f0' },
  { name: 'Legend of Zelda Dark-Purple', hex: '#500fc8' },
  { name: 'Nintendo Labo Creators "Cardboard"', hex: '#d7aa73' },
  { name: 'Pokémon Eevee-Brown', hex: '#c88c32' },
  { name: 'Pokémon Pikachu-Yellow', hex: '#ffdc00' },
  { name: 'Pokémon Scarlet', hex: '#f04614' },
  { name: 'Pokémon Violet', hex: '#b400e5' }
];
