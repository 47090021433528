import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Rail extends Component {
  render() {
    return (
      <Part
        activePart={parts.RIGHT_RAIL}
        activeGroup={parts.RIGHT_RAIL}
        activeGroupParts={groups.RIGHT_RAIL}
        smallHover={true}
      >
        {({ theme }) => (
          <path
            fill={theme[parts.RIGHT_RAIL]}
            d="M13.5,288.453H7.25c-0.552,0-1,0.448-1,1v9.73c0,0.177-0.047,0.351-0.136,0.504l-1.324,2.27 c-0.179,0.307-0.508,0.496-0.864,0.496H1c-0.552,0-1-0.448-1-1l0-53.797c0-0.134,0.027-0.267,0.079-0.391l1.991-4.692 c0.159-0.375,0.075-0.809-0.213-1.098l-1.564-1.564C0.105,239.725,0,239.471,0,239.205l0-24.56c0-0.329,0.162-0.637,0.433-0.824 l1.568-1.078c0.388-0.267,0.537-0.768,0.359-1.204l-2.286-5.571C0.025,205.848,0,205.719,0,205.589l0-93.303 c0-0.135,0.027-0.269,0.08-0.393l1.873-4.385c0.212-0.496-0.007-1.07-0.496-1.299l-0.882-0.413C0.225,105.633,0,105.28,0,104.891 l0-24.524c0-0.265,0.105-0.52,0.293-0.707l1.957-1.957c0.3-0.3,0.378-0.756,0.195-1.139l-2.347-4.907 C0.033,71.523,0,71.375,0,71.226l0-48.523c0-0.552,0.448-1,1-1h12.25"
          ></path>
        )}
      </Part>
    );
  }
}
