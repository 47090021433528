import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Home extends Component {
  render() {
    return (
      <Part
        activePart={parts.HOME_BUTTON_BACKGROUND}
        activeGroup={parts.HOME_BUTTON}
        activeGroupParts={groups.HOME_BUTTON}
      >
        {({ theme }) => (
          <g>
            <circle
              fill={theme[parts.HOME_BUTTON_BACKGROUND]}
              stroke={theme[parts.HOME_BUTTON_LIGHT]}
              strokeWidth="3"
              strokeMiterlimit="10"
              cx="42.7"
              cy="241.7"
              r="12.4"
            ></circle>
            <path
              fill={theme[parts.HOME_BUTTON_ICON]}
              d="M42.8,235.5c-0.1-0.1-0.2-0.1-0.3,0l-6.4,5.6h1.5c0.1,0,0.1,0,0.1,0.1v5.9c0,0.1,0,0.1,0.1,0.1h9.5
            c0.1,0,0.1,0,0.1-0.1v-5.9c0-0.1,0-0.1,0.1-0.1h1.7L42.8,235.5z M44.6,244.9c0,0.1,0,0.1-0.1,0.1h-3.7c-0.1,0-0.1,0-0.1-0.1v-3.1
            c0-0.1,0-0.1,0.1-0.1h3.7c0.1,0,0.1,0,0.1,0.1V244.9z"
            ></path>
          </g>
        )}
      </Part>
    );
  }
}
