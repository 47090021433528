import React, { Component } from 'react';
import classNames from 'classnames';
import jsonURL from 'json-url';
import Hotkeys from 'react-hot-keys';
import ntc from '@yatiac/name-that-color';

import Menu from './components/Menu/Menu.js';
import Tutorial from './components/Tutorial/Tutorial.js';
import CenterMenu from './components/CenterMenu/CenterMenu.js';
import LeftJoyCon from './components/JoyCons/LeftJoyCon.js';
import RightJoyCon from './components/JoyCons/RightJoyCon.js';
import Footer from './components/Footer/Footer.js';
import { LEFT_JOYCON, RIGHT_JOYCON } from './constants/parts.js';
import { presetColors } from './constants/preset-colors.js';

import Context, { initialContext } from './context/index.js';

export default class App extends Component {
  state = initialContext;

  setThemeTitle() {
    const getName = part => {
      return (
        presetColors.find(color => color.hex === this.state.theme[part])?.name || ntc(this.state.theme[part]).colorName
      );
    };

    const leftColorName = getName(LEFT_JOYCON);
    const rightColorName = getName(RIGHT_JOYCON);

    document.title = `${leftColorName} & ${rightColorName} | Joy-Con Studio`;
  }

  getThemeURL() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    const encodedTheme = params.theme;

    if (encodedTheme) {
      jsonURL('lzma')
        .decompress(encodedTheme)
        .then(({ darkBackgroundEnabled, theme }) => this.setContext({ darkBackgroundEnabled, theme }))
        .catch(() => window.history.replaceState(null, null, '/'));
    } else {
      this.setContext({ theme: { ...initialContext.theme } });
    }
  }

  onKeyDown = (keyName, event) => {
    if (event.key === 'z') {
      window.history.go(-1);
    } else if (event.key === 'y') {
      window.history.go(1);
    }
  };

  setContext = (newContext, callback) => {
    this.setState({ ...this.state, ...newContext }, callback);
  };

  componentDidUpdate() {
    window.onpopstate = () => this.getThemeURL();
    this.setThemeTitle();
  }

  componentDidMount() {
    this.setState({ setContext: this.setContext });
    this.getThemeURL();
    this.setThemeTitle();
  }

  render() {
    const { activePart, darkBackgroundEnabled, enlargeSettingEnabled, outlineSettingEnabled, tutorialIndex } =
      this.state;

    return (
      <Context.Provider value={this.state}>
        <Hotkeys keyName="ctrl+z, command+z, ctrl+y, command+y" onKeyDown={this.onKeyDown}>
          <main
            data-tutorial-index={tutorialIndex}
            className={classNames({
              'active-editing': activePart,
              'active-tutorial': tutorialIndex !== -1,
              dark: darkBackgroundEnabled,
              'enlarge-part': enlargeSettingEnabled,
              'outline-part': outlineSettingEnabled
            })}
          >
            {tutorialIndex !== -1 ? <Tutorial /> : <Menu />}
            <section id="editor">
              <LeftJoyCon />
              <div id="center-menu">
                <CenterMenu />
              </div>
              <RightJoyCon />
            </section>
            <Footer />
          </main>
        </Hotkeys>
      </Context.Provider>
    );
  }
}
