import React, { Component } from 'react';

import Rail from './Parts/Right/Rail.js';
import Trigger from './Parts/Right/Trigger.js';
import JoyCon from './Parts/Right/JoyCon.js';
import Plus from './Parts/Right/Plus.js';
import Thumbstick from './Parts/Right/Thumbstick.js';
import A from './Parts/Right/A.js';
import B from './Parts/Right/B.js';
import X from './Parts/Right/X.js';
import Y from './Parts/Right/Y.js';
import Home from './Parts/Right/Home.js';

export default class RightJoyCon extends Component {
  render() {
    return (
      <div className="joy-cons" id="right-joy-con">
        <svg
          className="animated bounceInDown fast"
          id="right-joy-con"
          version="1.1"
          viewBox="0 0 118 335"
          x="0px"
          y="0px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Rail />
          <Trigger />
          <JoyCon />
          <Plus />
          <Thumbstick />
          <X />
          <Y />
          <A />
          <B />
          <Home />
        </svg>
      </div>
    );
  }
}
