import React, { Component } from 'react';
import classNames from 'classnames';

import * as parts from '../../constants/parts.js';
import * as buttons from '../../constants/buttons.js';
import Context, { initialContext } from '../../context/index.js';

export default class Menu extends Component {
  static contextType = Context;

  startWalkthrough = () => {
    const { setContext, tutorialIndex } = this.context;

    tutorialIndex === -1
      ? setContext({ ...initialContext, outlineGroup: parts.LEFT_JOYCON, tutorialIndex: 0 })
      : setContext({ tutorialIndex: -1 });
  };

  toggleBackground = () => {
    this.context.setContext({ darkBackgroundEnabled: !this.context.darkBackgroundEnabled });
  };

  resetTheme = () => {
    this.context.setContext({ ...initialContext });
  };

  render() {
    const { outlineGroup, tutorialIndex } = this.context;

    return (
      <menu id="menu">
        <div className="buttons">
          <button
            id="tutorial-button"
            className={classNames({
              outline: tutorialIndex === 7 && buttons.TUTORIAL === outlineGroup ? true : false
            })}
            onClick={this.startWalkthrough}
          >
            {tutorialIndex !== -1 ? buttons.END_TUTORIAL : buttons.TUTORIAL}
          </button>
          <button
            className={classNames({
              outline: tutorialIndex === 7 && buttons.CLOSE === outlineGroup ? true : false
            })}
            onClick={this.toggleBackground}
          >
            {buttons.TOGGLE_BACKGROUND}
          </button>
          <button onClick={this.resetTheme}>{buttons.RESET_THEME}</button>
        </div>
      </menu>
    );
  }
}
