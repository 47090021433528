import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Thumbstick extends Component {
  render() {
    return (
      <Part
        activePart={parts.RIGHT_THUMBSTICK_TOP}
        activeGroup={parts.RIGHT_THUMBSTICK}
        activeGroupParts={groups.RIGHT_THUMBSTICK}
      >
        {({ theme }) => (
          <g>
            <circle fill={theme[parts.RIGHT_THUMBSTICK_BOTTOM]} cx="61.396" cy="179.39" r="25.191"></circle>
            <circle fill={theme[parts.RIGHT_THUMBSTICK_TOP]} cx="61.396" cy="179.39" r="18.637"></circle>
          </g>
        )}
      </Part>
    );
  }
}
