import React from 'react';

export function FaTrash() {
  return (
    <svg
      strokeWidth="0"
      viewBox="0 0 140.63255 160.72298"
      height="16.072458"
      width="14.063396"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 135.60998,10.045221 H 97.94052 L 94.98975,4.1750517 C 93.71477,1.6152607 91.1004,-0.0020063 88.24066,4.4698198e-5 H 52.3605 C 49.50613,-0.0097553 46.89668,1.6108317 45.6428,4.1750517 L 42.692,10.045221 H 5.0225705 c -2.7739,0 -5.02257000000002,2.248699 -5.02257000000002,5.022603 V 25.112999 C 4.9999998e-7,27.886904 2.2486705,30.135603 5.0225705,30.135603 H 135.60998 c 2.7739,0 5.02257,-2.248699 5.02257,-5.022604 V 15.067824 c 0,-2.773904 -2.24867,-5.022603 -5.02257,-5.022603 z"
        fill="currentColor"
      />
      <g pointerEvents="none" transform="translate(-107.83271,-488.27777)">
        <path
          fill="currentColor"
          d="m 124.5328,634.87471 c 0.49724,7.93973 7.08113,14.12503 15.03639,14.12604 h 77.15959 c 7.95527,-9.5e-4 14.53916,-6.18631 15.03636,-14.12604 l 6.65495,-106.41616 h -120.5422 z"
        />
      </g>
    </svg>
  );
}
