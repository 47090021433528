import React, { Component } from 'react';
import jsonURL from 'json-url';
import classNames from 'classnames';

import Parts from './Parts.js';
import PatternPicker from './PatternPicker.js';
import ColorPicker from './ColorPicker.js';
import ApplyToAll from './ApplyToAll.js';

import * as buttons from '../../constants/buttons.js';
import * as parts from '../../constants/parts.js';
import Context from '../../context/index.js';

export default class CenterMenu extends Component {
  static contextType = Context;

  setColor = ({ color }) => {
    this.context.setContext({
      activeColor: color.hex,
      theme: { ...this.context.theme, [this.context.activePart]: color.hex }
    });
  };

  setThemeURL() {
    const { theme } = this.context;

    const setQueryParams = theme => {
      let url = new URL(window.location);
      url.searchParams.set('theme', theme);
      window.history.pushState({}, '', url);
    };

    jsonURL('lzma')
      .compress({ darkBackgroundEnabled: this.context.darkBackgroundEnabled, theme })
      .then(result => setQueryParams(result));
  }

  close = () => {
    this.context.setContext({ activePart: null, activeGroupParts: [] });
  };

  quit = () => {
    this.context.setContext({ tutorialIndex: -1 });
  };

  componentDidUpdate() {
    this.setThemeURL();
  }

  render() {
    const { activePart, outlineGroup, tutorialIndex, setContext } = this.context;

    return (
      <div>
        {activePart && (
          <div id="color-picker">
            <Parts />
            <ApplyToAll />
            {activePart === parts.LEFT_JOYCON_PATTERN || activePart === parts.RIGHT_JOYCON_PATTERN ? (
              <PatternPicker />
            ) : (
              <ColorPicker setColor={this.setColor} />
            )}

            <button
              className={classNames({
                outline: tutorialIndex === 7 && buttons.CLOSE === outlineGroup ? true : false,
                'bg-neutral-700': true,
                'text-white': true
              })}
              onClick={() => (tutorialIndex !== -1 ? this.quit() : this.close())}
            >
              <span>{tutorialIndex !== -1 ? buttons.END_TUTORIAL : buttons.CLOSE}</span>
            </button>
          </div>
        )}
      </div>
    );
  }
}
