import React, { Component } from 'react';

import * as parts from '../../constants/parts.js';
import Context from '../../context/index.js';

export default class CenterMenu extends Component {
  static contextType = Context;

  setPattern = async (event, part, widthPart, heightPart) => {
    const { setContext, theme } = this.context;
    const { value } = event.target;

    const loadImage = src => {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    };

    const { width, height } = value ? await loadImage(value) : { width: 0, height: 0 };

    await setContext({ theme: { ...theme, [part]: value, [widthPart]: width, [heightPart]: height } });
  };

  render() {
    const { activePart, theme, setContext } = this.context;
    const {
      LEFT_JOYCON_PATTERN,
      LEFT_JOYCON_PATTERN_WIDTH,
      LEFT_JOYCON_PATTERN_HEIGHT,
      RIGHT_JOYCON_PATTERN,
      RIGHT_JOYCON_PATTERN_WIDTH,
      RIGHT_JOYCON_PATTERN_HEIGHT
    } = parts;

    const joycons = [
      { pattern: LEFT_JOYCON_PATTERN, width: LEFT_JOYCON_PATTERN_WIDTH, height: LEFT_JOYCON_PATTERN_HEIGHT },
      { pattern: RIGHT_JOYCON_PATTERN, width: RIGHT_JOYCON_PATTERN_WIDTH, height: RIGHT_JOYCON_PATTERN_HEIGHT }
    ];

    return (
      <div id="pattern-picker">
        {joycons.map(({ pattern, width, height }) =>
          activePart === pattern ? (
            <div key={pattern}>
              <span className="part-group">
                <label>URL</label>
              </span>
              <input
                ref={ref => ref && ref.focus()}
                onChange={event => this.setPattern(event, [pattern], [width], [height])}
                type="text"
                value={theme[pattern]}
              />
              <div className="pattern-picker-dimensions">
                <div>
                  <span className="part-group">
                    <label>Width</label>
                  </span>

                  <input
                    onChange={event => setContext({ theme: { ...theme, [width]: event.target.value } })}
                    type="text"
                    value={theme[width]}
                  />
                </div>
                <div>
                  <span className="part-group">
                    <label>Height</label>
                  </span>
                  <input
                    onChange={event => setContext({ theme: { ...theme, [height]: event.target.value } })}
                    type="text"
                    value={theme[height]}
                  />
                </div>
              </div>
            </div>
          ) : null
        )}
      </div>
    );
  }
}
