import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class A extends Component {
  render() {
    return (
      <Part activePart={parts.A_BUTTON_BACKGROUND} activeGroup={parts.A_BUTTON} activeGroupParts={groups.A_BUTTON}>
        {({ theme }) => (
          <g>
            <circle fill={theme[parts.A_BUTTON_BACKGROUND]} cx="87.42" cy="87.89" r="12.099"></circle>
            <path
              fill={theme[parts.A_BUTTON_TEXT]}
              d="M92.4,92.6h-1.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-1-2.5h-4.6l-1,2.5c0,0.1-0.1,0.2-0.2,0.2
                c-0.1,0.1-0.2,0.1-0.3,0.1h-1.1l4.1-10.3h1.4L92.4,92.6z M89.5,88.8l-1.6-4.2c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
                c-0.1,0.4-0.2,0.7-0.3,1l-1.6,4.2H89.5z"
            ></path>
          </g>
        )}
      </Part>
    );
  }
}
