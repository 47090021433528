import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Left extends Component {
  render() {
    return (
      <Part
        activePart={parts.LEFT_BUTTON_BACKGROUND}
        activeGroup={parts.LEFT_BUTTON}
        activeGroupParts={groups.LEFT_BUTTON}
      >
        {({ theme }) => (
          <g>
            <circle fill={theme[parts.LEFT_BUTTON_BACKGROUND]} cx="32.099" cy="179.886" r="12.099"></circle>
            <polygon fill={theme[parts.LEFT_BUTTON_ICON]} points="27.8,179.9 34.4,176.1 34.4,183.7"></polygon>
          </g>
        )}
      </Part>
    );
  }
}
