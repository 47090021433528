import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Plus extends Component {
  render() {
    return (
      <Part activePart={parts.PLUS_BUTTON} activeGroup={parts.PLUS_BUTTON} activeGroupParts={groups.PLUS_BUTTON}>
        {({ theme }) => (
          <g>
            <rect
              fill={theme[parts.PLUS_BUTTON]}
              height="5.667"
              transform="matrix(-1 -4.486867e-11 4.486867e-11 -1 55.3333 72.2402)"
              width="18"
              x="18.667"
              y="33.287"
            ></rect>
            <rect
              fill={theme[parts.PLUS_BUTTON]}
              height="5.667"
              transform="matrix(4.486906e-11 -1 1 4.486906e-11 -8.4534 63.7868)"
              width="18"
              x="18.667"
              y="33.287"
            ></rect>
          </g>
        )}
      </Part>
    );
  }
}
