import React, { Component } from 'react';
import classNames from 'classnames';

import { labels } from '../../../constants/labels.js';
import * as parts from '../../../constants/parts.js';
import Context from '../../../context/index.js';

export default class Part extends Component {
  static contextType = Context;

  isDisabled(activePart) {
    const { tutorialIndex } = this.context;

    return (
      (tutorialIndex === 0 && activePart !== parts.LEFT_JOYCON) ||
      (tutorialIndex === 1 ? true : false) ||
      (tutorialIndex === 2 && activePart !== parts.PLUS_BUTTON) ||
      (tutorialIndex === 3 ? true : false) ||
      (tutorialIndex === 4 && activePart !== parts.HOME_BUTTON) ||
      (tutorialIndex === 5 ? true : false) ||
      (tutorialIndex === 6 ? true : false)
    );
  }

  render() {
    const { outlineGroup, outlineSettingEnabled, setContext, theme, tutorialIndex } = this.context;
    const { activePart, activeGroup, activeGroupParts } = this.props;

    return (
      <g
        className={classNames({
          disabled: this.isDisabled(activeGroup),
          outline:
            (tutorialIndex === 0 && activeGroup === outlineGroup) ||
            (tutorialIndex === 2 && activeGroup === outlineGroup) ||
            (tutorialIndex === 4 && activeGroup === outlineGroup)
              ? true
              : outlineSettingEnabled && this.context.activeGroupParts.includes(activeGroup),
          'small-hover': this.props.smallHover
        })}
        onClick={() =>
          !this.isDisabled(activeGroup) &&
          setContext({
            activePart,
            activeGroup,
            activeGroupParts,
            tutorialIndex: tutorialIndex === 5 ? -1 : tutorialIndex
          })
        }
      >
        <title>{labels[activeGroup]}</title>
        {this.props.children({ theme })}
      </g>
    );
  }
}
