import * as parts from './parts.js';

export const labels = {
  [parts.SCREENSHOT_BUTTON]: 'Screenshot Button',
  [parts.SCREENSHOT_BUTTON_BACKGROUND]: 'Button',
  [parts.SCREENSHOT_BUTTON_ICON]: 'Icon',

  [parts.HOME_BUTTON]: 'Home Button',
  [parts.HOME_BUTTON_BACKGROUND]: 'Button',
  [parts.HOME_BUTTON_ICON]: 'Icon',
  [parts.HOME_BUTTON_LIGHT]: 'Light Ring',

  [parts.LEFT_THUMBSTICK]: 'Left Thumbstick',
  [parts.LEFT_THUMBSTICK_TOP]: 'Upper',
  [parts.LEFT_THUMBSTICK_BOTTOM]: 'Lower',

  [parts.RIGHT_THUMBSTICK]: 'Right Thumbstick',
  [parts.RIGHT_THUMBSTICK_TOP]: 'Upper',
  [parts.RIGHT_THUMBSTICK_BOTTOM]: 'Lower',

  [parts.UP_BUTTON]: 'Up D-Pad',
  [parts.UP_BUTTON_BACKGROUND]: 'Button',
  [parts.UP_BUTTON_ICON]: 'Arrow',

  [parts.RIGHT_BUTTON]: 'Right D-Pad',
  [parts.RIGHT_BUTTON_BACKGROUND]: 'Button',
  [parts.RIGHT_BUTTON_ICON]: 'Arrow',

  [parts.DOWN_BUTTON]: 'Down D-Pad',
  [parts.DOWN_BUTTON_BACKGROUND]: 'Button',
  [parts.DOWN_BUTTON_ICON]: 'Arrow',

  [parts.LEFT_BUTTON]: 'Left D-Pad',
  [parts.LEFT_BUTTON_BACKGROUND]: 'Button',
  [parts.LEFT_BUTTON_ICON]: 'Arrow',

  [parts.A_BUTTON]: 'A Button',
  [parts.A_BUTTON_BACKGROUND]: 'Button',
  [parts.A_BUTTON_TEXT]: 'Letter',

  [parts.B_BUTTON]: 'B Button',
  [parts.B_BUTTON_BACKGROUND]: 'Button',
  [parts.B_BUTTON_TEXT]: 'Letter',

  [parts.X_BUTTON]: 'X Button',
  [parts.X_BUTTON_BACKGROUND]: 'Button',
  [parts.X_BUTTON_TEXT]: 'Letter',

  [parts.Y_BUTTON]: 'Y Button',
  [parts.Y_BUTTON_BACKGROUND]: 'Button',
  [parts.Y_BUTTON_TEXT]: 'Letter',

  [parts.MINUS_BUTTON]: 'Color',
  [parts.PLUS_BUTTON]: 'Color',

  [parts.LEFT_JOYCON]: 'Color',
  [parts.LEFT_JOYCON_PATTERN]: 'Pattern',
  [parts.LEFT_RAIL]: 'Color',
  [parts.LEFT_TRIGGER]: 'Color',

  [parts.RIGHT_JOYCON]: 'Color',
  [parts.RIGHT_JOYCON_PATTERN]: 'Pattern',
  [parts.RIGHT_RAIL]: 'Color',
  [parts.RIGHT_TRIGGER]: 'Color'
};
