import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Y extends Component {
  render() {
    return (
      <Part activePart={parts.Y_BUTTON_BACKGROUND} activeGroup={parts.Y_BUTTON} activeGroupParts={groups.Y_BUTTON}>
        {({ theme }) => (
          <g>
            <circle fill={theme[parts.Y_BUTTON_BACKGROUND]} cx="35.087" cy="87.89" r="12.099"></circle>
            <path
              fill={theme[parts.Y_BUTTON_TEXT]}
              d="M35.7,88.5v4.1h-1.4v-4.1l-3.8-6.2h1.2c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.2,0.2l2.4,4c0.1,0.2,0.2,0.3,0.2,0.5
            c0.1,0.1,0.1,0.3,0.2,0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.5l2.4-4c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1
            h1.2L35.7,88.5z"
            ></path>
          </g>
        )}
      </Part>
    );
  }
}
