import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Down extends Component {
  render() {
    return (
      <Part
        activePart={parts.DOWN_BUTTON_BACKGROUND}
        activeGroup={parts.DOWN_BUTTON}
        activeGroupParts={groups.DOWN_BUTTON}
      >
        {({ theme }) => (
          <g>
            <circle fill={theme[parts.DOWN_BUTTON_BACKGROUND]} cx="58.099" cy="203.886" r="12.099"></circle>
            <polygon fill={theme[parts.DOWN_BUTTON_ICON]} points="58.1,208.7 54.3,202.2 61.9,202.2"></polygon>
          </g>
        )}
      </Part>
    );
  }
}
