import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class X extends Component {
  render() {
    return (
      <Part activePart={parts.X_BUTTON_BACKGROUND} activeGroup={parts.X_BUTTON} activeGroupParts={groups.X_BUTTON}>
        {({ theme }) => (
          <g>
            <circle fill={theme[parts.X_BUTTON_BACKGROUND]} cx="61.42" cy="62.692" r="12.099"></circle>
            <path
              fill={theme[parts.X_BUTTON_TEXT]}
              d="M65.9,67.6h-1.4c-0.1,0-0.2,0-0.2-0.1s-0.1-0.1-0.1-0.2L61.3,63c0,0.1-0.1,0.2-0.1,0.3l-2.7,4c0,0.1-0.1,0.1-0.2,0.2
            c-0.1,0.1-0.1,0.1-0.2,0.1h-1.3l3.5-5.3l-3.4-5h1.4c0.1,0,0.2,0,0.2,0.1s0.1,0.1,0.1,0.1l2.7,4.1c0-0.1,0.1-0.2,0.2-0.3l2.5-3.8
            c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1h1.3l-3.4,4.9L65.9,67.6z"
            ></path>
          </g>
        )}
      </Part>
    );
  }
}
