import React, { Component } from 'react';

import { groups } from '../../../../constants/groups.js';
import * as parts from '../../../../constants/parts.js';
import Part from '../Part.js';

export default class Home extends Component {
  render() {
    return (
      <Part
        activePart={parts.SCREENSHOT_BUTTON_BACKGROUND}
        activeGroup={parts.SCREENSHOT_BUTTON}
        activeGroupParts={groups.SCREENSHOT_BUTTON}
      >
        {({ theme }) => (
          <g>
            <path
              fill={theme[parts.SCREENSHOT_BUTTON_BACKGROUND]}
              d="M83.667,252.453h-15c-1.65,0-3-1.35-3-3v-15c0-1.65,1.35-3,3-3h15c1.65,0,3,1.35,3,3v15 C86.667,251.103,85.317,252.453,83.667,252.453z"
            ></path>
            <circle fill={theme[parts.SCREENSHOT_BUTTON_ICON]} cx="76.2" cy="242" r="6.2"></circle>
          </g>
        )}
      </Part>
    );
  }
}
